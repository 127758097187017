import React from 'react';
import PropTypes from 'prop-types';

const SurveyPage = ({ pageContext }) => {
  console.log(pageContext);

  return <div>deneme</div>;
};

SurveyPage.propTypes = {
  pageContext: PropTypes.object,
};

export default SurveyPage;
